// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-page-data-case-detail-js": () => import("./../../../src/components/pageData/caseDetail.js" /* webpackChunkName: "component---src-components-page-data-case-detail-js" */),
  "component---src-components-page-data-cases-js": () => import("./../../../src/components/pageData/cases.js" /* webpackChunkName: "component---src-components-page-data-cases-js" */),
  "component---src-components-page-data-documents-js": () => import("./../../../src/components/pageData/documents.js" /* webpackChunkName: "component---src-components-page-data-documents-js" */),
  "component---src-components-page-data-goals-js": () => import("./../../../src/components/pageData/goals.js" /* webpackChunkName: "component---src-components-page-data-goals-js" */),
  "component---src-components-page-data-index-js": () => import("./../../../src/components/pageData/index.js" /* webpackChunkName: "component---src-components-page-data-index-js" */),
  "component---src-components-page-data-news-detail-js": () => import("./../../../src/components/pageData/newsDetail.js" /* webpackChunkName: "component---src-components-page-data-news-detail-js" */),
  "component---src-components-page-data-news-js": () => import("./../../../src/components/pageData/news.js" /* webpackChunkName: "component---src-components-page-data-news-js" */),
  "component---src-components-page-data-programs-js": () => import("./../../../src/components/pageData/programs.js" /* webpackChunkName: "component---src-components-page-data-programs-js" */),
  "component---src-components-page-data-targets-js": () => import("./../../../src/components/pageData/targets.js" /* webpackChunkName: "component---src-components-page-data-targets-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-en-article-preview-js": () => import("./../../../src/pages/en/article_preview.js" /* webpackChunkName: "component---src-pages-en-article-preview-js" */),
  "component---src-pages-en-case-preview-js": () => import("./../../../src/pages/en/case_preview.js" /* webpackChunkName: "component---src-pages-en-case-preview-js" */),
  "component---src-pages-en-goal-preview-js": () => import("./../../../src/pages/en/goal_preview.js" /* webpackChunkName: "component---src-pages-en-goal-preview-js" */),
  "component---src-pages-en-news-js": () => import("./../../../src/pages/en/news.js" /* webpackChunkName: "component---src-pages-en-news-js" */),
  "component---src-pages-en-program-preview-js": () => import("./../../../src/pages/en/program_preview.js" /* webpackChunkName: "component---src-pages-en-program-preview-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ru-article-preview-js": () => import("./../../../src/pages/ru/article_preview.js" /* webpackChunkName: "component---src-pages-ru-article-preview-js" */),
  "component---src-pages-ru-case-preview-js": () => import("./../../../src/pages/ru/case_preview.js" /* webpackChunkName: "component---src-pages-ru-case-preview-js" */),
  "component---src-pages-ru-goal-preview-js": () => import("./../../../src/pages/ru/goal_preview.js" /* webpackChunkName: "component---src-pages-ru-goal-preview-js" */),
  "component---src-pages-ru-news-js": () => import("./../../../src/pages/ru/news.js" /* webpackChunkName: "component---src-pages-ru-news-js" */),
  "component---src-pages-ru-program-preview-js": () => import("./../../../src/pages/ru/program_preview.js" /* webpackChunkName: "component---src-pages-ru-program-preview-js" */)
}

