import 'element-closest-polyfill'

import FlowAdaptive from 'flow-adaptive'

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const onClientEntry = async () => {
  new FlowAdaptive({
    fontRatio: 1375 / 10,
    // minFont: 5,
    // maxFont: 15,
    breakPoints: [
      {
        maxWidth: 650,
        fontRatio: 320 / 10,
      },
    ],
  })
}

export {
  onClientEntry
}
